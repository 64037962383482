import {
  type FigurationInfoFragment,
  RateType,
  ScaleBasis,
  type VisibleInvestorOrderInfoWithAllocationsFragment,
  type ChargeGroup,
  CriteriaField,
  CriteriaCombinator,
  CriteriaOperator,
  type ChargeScaleDetails,
  type Maybe,
  type ScaleRateType,
  type ChargesRollupInfoFragment,
  type ChargesRollupFragment
} from '@oms/generated/frontend';
import Decimal from 'decimal.js';
import {
  format,
  DISPLAY_FIELD_COMPONENT_TYPE,
  type DisplayGridItemProps,
  type DisplayGridProps,
  type GridColValues,
  MAX_COLUMN_SIZE
} from '@oms/shared-frontend/ui-design-system';
import type { MatchCriteriaFormOutput, MatchCriteriaInput } from './charges.types';
import { t } from '@oms/codegen/translations';
import { mapRateType } from '@app/common/mappers/map-rate-type';
import { mapScaleBasis } from '@app/common/mappers/map-scale-basis';
import { mapScaleRateType } from '@app/common/mappers/map-scale-rate-type';
import { DateTime } from 'luxon';
import { formatNumber } from '@oms/shared/util';

const COMMISSION = 'Commission';
interface TradingDayToChargesMap {
  [key: string]: ChargesRollupFragment[];
}

export const commissionRateType = (figurations: FigurationInfoFragment[] | undefined): string => {
  if (!figurations) return '';

  const commissionFiguration = figurations.find((figuration) => {
    if (figuration?.isManual) return figuration?.manualCharge?.name === COMMISSION;

    return figuration?.chargeSchedule?.charge?.name === COMMISSION;
  });
  if (!commissionFiguration) return '';
  const commissionRateType = commissionFiguration?.isManual
    ? commissionFiguration?.manualRateType
    : commissionFiguration?.chargeSchedule?.rateType;
  if (!commissionRateType) return '';

  return mapRateType(commissionRateType);
};

export const commissionRateValue = (
  figurations: FigurationInfoFragment[] | undefined,
  visibleInvestorOrder: VisibleInvestorOrderInfoWithAllocationsFragment | undefined
): string => {
  if (!figurations) return '';
  if (!visibleInvestorOrder) return '';

  const commissionFiguration = figurations.find((figuration) => {
    if (figuration?.isManual) return figuration?.manualCharge?.name === COMMISSION;

    return figuration?.chargeSchedule?.charge?.name === COMMISSION;
  });
  if (!commissionFiguration) return '';

  const commissionRateValue = commissionFiguration?.isManual
    ? commissionFiguration?.manualRateValue
    : commissionFiguration?.chargeSchedule?.rateType === RateType.Scale
    ? getRateValueFromScale(commissionFiguration, visibleInvestorOrder)
    : commissionFiguration?.chargeSchedule?.rateValue;
  if (!commissionRateValue) return '';

  return format('number', commissionRateValue ?? '');
};

const getRateValueFromScale = (
  figuration: FigurationInfoFragment,
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment
): number | undefined | null => {
  const { chargeSchedule } = figuration;

  const rows = chargeSchedule?.scaleSchedule?.scale?.rows ?? [];
  const rowValueDec = getRowValueFromScale(figuration, investorOrder);

  for (const row of rows) {
    const toDec = new Decimal(row?.to ?? 0);
    const fromDec = new Decimal(row?.from ?? 0);

    const rateValue = row?.rateValue;

    if (toDec.equals(-1)) return rateValue; // LAST ROW
    if (rowValueDec.greaterThanOrEqualTo(fromDec) && rowValueDec.lessThanOrEqualTo(toDec)) return rateValue;
  }

  return undefined;
};

const getRowValueFromScale = (
  figuration: FigurationInfoFragment,
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment
): Decimal => {
  const { executedQuantity, averagePrice } = investorOrder;
  const { chargeSchedule } = figuration;

  const quantityDec = new Decimal(executedQuantity ?? 0);
  const priceDec = new Decimal(averagePrice ?? 0);

  switch (chargeSchedule?.scaleSchedule?.scale?.basis) {
    case ScaleBasis.OrderValue: {
      return quantityDec.times(priceDec).toDecimalPlaces(2);
    }
    case ScaleBasis.Quantity: {
      return quantityDec.toDecimalPlaces(2);
    }
    default: {
      return new Decimal(0);
    }
  }
};

export const commissionAmount = (chargesRollup: ChargesRollupInfoFragment | undefined): string => {
  if (!chargesRollup) return '';

  const totalAmounts = chargesRollup?.total;
  const commissionChargeCalculation = totalAmounts?.find(
    (chargesRollup) => chargesRollup.chargeName === COMMISSION
  );

  if (!commissionChargeCalculation) return '';

  const amount = commissionChargeCalculation?.amount;
  if (!amount) return '';
  if (amount === 0) return '';

  return format('number', amount);
};

export const chargeGroupTotal = (
  chargeGroup: ChargeGroup,
  order: VisibleInvestorOrderInfoWithAllocationsFragment
): DisplayGridProps['items'] => {
  const chargesRollup = order?.chargesRollup;
  if (!chargesRollup) return [];

  const { today: todayCharges, total: totalCharges, daily: dailyCharges } = chargesRollup;

  // TODAY
  const filteredTodayChargeGroup = todayCharges.filter((calc) => calc?.chargeGroup === chargeGroup);

  // TOTAL
  const filteredTotalChargeGroup = totalCharges.filter((calc) => calc?.chargeGroup === chargeGroup);
  if (filteredTotalChargeGroup.length === 0) return [];

  const columns = filteredTotalChargeGroup.length + 1;

  const totalByChargeGroup =
    filteredTotalChargeGroup
      .sort((chargeRowA, chargeRowB) =>
        (chargeRowA?.chargeName ?? '').localeCompare(chargeRowB?.chargeName ?? '')
      )
      .map((calc) => calc?.amount)
      .reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0) ?? 0;
  const total = totalByChargeGroup === 0 ? '' : formatNumber(totalByChargeGroup);

  const totalChargeByGroupComponent: DisplayGridItemProps = {
    component: {
      type: DISPLAY_FIELD_COMPONENT_TYPE.Link,
      text: total
    }
  };

  if (columns > MAX_COLUMN_SIZE)
    return [
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
          popoverContent: {
            gridProps: {
              rows: 1,
              columns: 1,
              rowGap: 0,
              columnGap: 0
            },
            items: [
              {
                component: {
                  type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
                  value: 'Too many charges to display'
                }
              }
            ],
            hasClose: false
          },
          value: totalChargeByGroupComponent
        }
      }
    ];

  const gridProps: DisplayGridProps['gridProps'] = {
    rows: 2,
    columns: columns as GridColValues,
    columnGap: 5,
    rowGap: 0
  };
  const chargeColumns: DisplayGridProps['items'] = filteredTotalChargeGroup.flatMap((calc) => {
    return [
      {
        label: calc.chargeName,
        layout: 'vertical',
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: ''
        },
        rowSpan: 1
      }
    ];
  });
  const chargeItemsForTotal: DisplayGridProps['items'] = filteredTotalChargeGroup.flatMap((calc) => {
    return [
      {
        label: ' ',
        layout: 'vertical',
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: calc?.amount || ''
        },
        rowSpan: 1
      }
    ];
  });

  const chargeItemsForToday: DisplayGridProps['items'] = filteredTodayChargeGroup.flatMap((calc) => {
    return [
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: calc?.amount || ''
        },
        rowSpan: 1
      }
    ];
  });
  const filteredDailyCharges = dailyCharges.reduce((acc, { tradingDay, charges }) => {
    const filteredChargeGroup = charges.filter((charge) => charge.chargeGroup === chargeGroup);
    acc[tradingDay] = filteredChargeGroup;

    return acc;
  }, {} as TradingDayToChargesMap);

  const chargeItemsDaily = Object.keys(filteredDailyCharges).flatMap((tradingDay) => {
    const charges = filteredDailyCharges[tradingDay] ?? [];
    const tradingDayDateTime = DateTime.fromISO(tradingDay);

    return [
      {
        label: `${tradingDayDateTime.monthShort} ${tradingDayDateTime.day}`,
        width: '6rem',
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      ...charges.map((calc) => ({
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: calc?.amount || ''
        }
      }))
    ];
  });

  // Same day
  const todaysTradingDaySet = todayCharges.reduce<Set<string>>((acc, calc) => {
    acc.add(calc.tradingDay);
    return acc;
  }, new Set<string>());
  const dailyTradeDaySet = dailyCharges.reduce<Set<string>>((acc, calc) => {
    acc.add(calc.tradingDay);
    return acc;
  }, new Set<string>());

  const hideDailyCharges = dailyTradeDaySet.size === 1 && todaysTradingDaySet.has(dailyCharges[0].tradingDay);

  const items: DisplayGridProps['items'] = [
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    ...chargeColumns,
    {
      label: 'Today',
      width: '6rem',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    ...(chargeItemsForToday.length === 0
      ? [
          {
            component: {
              type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
              value: ''
            },
            colSpan: chargeColumns.length
          }
        ]
      : chargeItemsForToday),
    ...(hideDailyCharges ? [] : chargeItemsDaily),
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      sx: { borderColor: 'border.subtle' },
      style: { borderBottom: '1px solid', marginTop: 5, marginBottom: 5 },
      colSpan: columns
    },
    {
      label: 'Total',
      width: '6rem',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    ...chargeItemsForTotal
  ] as DisplayGridProps['items'];

  return [
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
        popoverContent: {
          gridProps,
          items: items,
          hasClose: false
        },
        value: totalChargeByGroupComponent
      }
    }
  ];
};

export const toMatchCriteriaInput = (ruleBuilderOutput: MatchCriteriaFormOutput): MatchCriteriaInput => {
  const { id, priority, combinator, rules } = ruleBuilderOutput;
  const formattedCombinator = combinator === 'and' ? CriteriaCombinator.And : CriteriaCombinator.Or;

  return {
    id,
    priority,
    input: {
      combinator: formattedCombinator,
      criterias: rules.map((rule) => ({
        field: rule?.value?.value as CriteriaField,
        operator: CriteriaOperator.Equal,
        value: {
          label: undefined,
          id: rule?.value?.value
        }
      }))
    }
  };
};

export const criteriaFieldStringMap = () => ({
  [CriteriaField.Account]: t('app.charges.criteriaFields.account'),
  [CriteriaField.AccountType]: t('app.charges.criteriaFields.accountType'),
  [CriteriaField.AccountSubType]: t('app.charges.criteriaFields.accountSubType'),
  [CriteriaField.AccountCountryOfDomicile]: t('app.charges.criteriaFields.accountCountryOfDomicile'),
  [CriteriaField.AccountList]: t('app.charges.criteriaFields.accountList'),
  [CriteriaField.Exchange]: t('app.charges.criteriaFields.exchange'),
  [CriteriaField.Instrument]: t('app.charges.criteriaFields.instrument'),
  [CriteriaField.InstrumentCountryOfIncorporation]: t(
    'app.charges.criteriaFields.instrumentCountryOfIncorporation'
  ),
  [CriteriaField.InstrumentList]: t('app.charges.criteriaFields.instrumentList'),
  [CriteriaField.Region]: t('app.charges.criteriaFields.region'),
  [CriteriaField.SecurityType]: t('app.charges.criteriaFields.securityType'),
  [CriteriaField.SettleDateEffectiveOn]: t('app.charges.criteriaFields.settleDateEffectiveOn'),
  [CriteriaField.SettleDateEffectiveTill]: t('app.charges.criteriaFields.settleDateEffectiveTill'),
  [CriteriaField.SettlementCurrency]: t('app.charges.criteriaFields.settlementCurrency'),
  [CriteriaField.Side]: t('app.charges.criteriaFields.side'),
  [CriteriaField.TradeCurrency]: t('app.charges.criteriaFields.tradeCurrency'),
  [CriteriaField.TradeDateEffectiveOn]: t('app.charges.criteriaFields.tradeDateEffectiveOn'),
  [CriteriaField.TradeDateEffectiveTill]: t('app.charges.criteriaFields.tradeDateEffectiveTill')
});

export const criteriaFieldtoMapperId = (): Record<CriteriaField, string> => ({
  [CriteriaField.Account]: 'investorAccount',
  [CriteriaField.AccountType]: 'accountType',
  [CriteriaField.AccountSubType]: 'accountSubType',
  [CriteriaField.AccountCountryOfDomicile]: 'country',
  [CriteriaField.AccountList]: 'accountList',
  [CriteriaField.Exchange]: 'listingExchange',
  [CriteriaField.Instrument]: 'instrument',
  [CriteriaField.InstrumentCountryOfIncorporation]: 'countryOfIncorporation',
  [CriteriaField.InstrumentList]: 'instrumentList',
  [CriteriaField.Region]: 'region',
  [CriteriaField.SecurityType]: 'securityType',
  [CriteriaField.SettleDateEffectiveOn]: 'settleDateTill',
  [CriteriaField.SettleDateEffectiveTill]: 'settleDateOn',
  [CriteriaField.SettlementCurrency]: 'settleCurrency',
  [CriteriaField.Side]: 'side',
  [CriteriaField.TradeCurrency]: 'tradeCurrency',
  [CriteriaField.TradeDateEffectiveOn]: 'tradeDateOn',
  [CriteriaField.TradeDateEffectiveTill]: 'tradeDateTill'
});

export const gridPopoverChargeScaleProps: DisplayGridProps['gridProps'] = {
  columns: 2,
  rows: 4,
  columnGap: 0,
  rowGap: 0
};

type GridPopoverChargeScaleProps = {
  chargeName?: string;
  scaleName?: string;
  basis?: ScaleBasis;
  rateType?: ScaleRateType;
  rows?: Maybe<ChargeScaleDetails>[];
};
export const gridPopoverChargeScale = ({
  chargeName,
  scaleName,
  basis,
  rateType,
  rows
}: GridPopoverChargeScaleProps): DisplayGridProps['items'] => {
  const detailRows: DisplayGridProps['items'] =
    rows?.flatMap((row, index) => {
      const rowNumber = index + 1;
      const to = row?.to === -1 ? '' : row?.to;

      return [
        {
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: ''
          },
          // HOW TO ADD COLOR TOKEN FOR BORDER? Border/Subtle #30373D
          style: { borderBottom: '1px solid', borderColor: '#30373D', marginTop: 5, marginBottom: 5 }
        },
        {
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: ''
          },
          // HOW TO ADD COLOR TOKEN FOR BORDER? Border/Subtle #30373D
          style: { borderBottom: '1px solid', borderColor: '#30373D', marginTop: 5, marginBottom: 5 }
        },
        {
          label: `SCALE ${rowNumber}`,
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: ''
          }
        },
        {
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: ''
          }
        },
        {
          label: 'Range',
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: ''
          }
        },
        {
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: `${row?.from} - ${to}`
          }
        },
        {
          label: 'Value',
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
            value: ''
          }
        },
        {
          component: {
            type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
            format: 'price',
            value: row?.rateValue ?? ''
          }
        }
      ];
    }) ?? [];

  return [
    {
      label: 'Charge',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      layout: 'horizontal'
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: chargeName ?? ''
      },
      layout: 'horizontal'
    },
    {
      label: 'Scale Name',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      layout: 'horizontal',
      labelMargin: 5
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: scaleName ?? ''
      },
      layout: 'horizontal'
    },
    {
      label: 'Scale Basis',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: basis ? mapScaleBasis(basis) : ''
      }
    },
    {
      label: 'Rate Type',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: rateType ? mapScaleRateType(rateType) : ''
      }
    },
    ...detailRows
  ];
};

export const gridPopoverChargeScheduleProps: DisplayGridProps['gridProps'] = {
  columns: 1,
  rows: 3,
  columnGap: 0,
  rowGap: 0
};

type GridPopoverChargeScheduleProps = {
  chargeName?: string;
  rateType?: RateType;
  rateValue?: number;
  min?: number;
  max?: number;
};
export const gridPopoverChargeSchedule = ({
  chargeName,
  rateType,
  rateValue,
  min,
  max
}: GridPopoverChargeScheduleProps): DisplayGridProps['items'] => {
  const items: DisplayGridProps['items'] = [
    {
      label: 'Charge',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: chargeName ?? ''
      }
    },
    {
      label: 'Rate Type',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: rateType ? mapRateType(rateType) : ''
      }
    },
    {
      label: 'Rate Value',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: rateValue ?? ''
      }
    }
  ];

  if (min) {
    items.push({
      label: 'Min',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: min
      }
    });
  }

  if (max) {
    items.push({
      label: 'Max',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: max
      }
    });
  }

  return items;
};
