import { type Observable } from 'rxjs';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import {
  GetTradeRepairRequestsDocument,
  type GetTradeRepairRequestsQuery,
  type GetTradeRepairRequestsQueryVariables,
  type TradeRepairRequestFragment,
  GetTradeRepairRequestByIdDocument,
  type GetTradeRepairRequestByIdQuery,
  type GetTradeRepairRequestByIdQueryVariables,
  RepairCreateTradeRepairRequestsDocument,
  type RepairCreateTradeRepairRequests,
  type RepairCreateTradeRepairRequestsMutationVariables,
  RejectTradeRepairRequestsDocument,
  type RejectTradeRepairRequestsMutation,
  type RejectTradeRepairRequestsMutationVariables,
  RetryCreateTradeRequestsDocument,
  type RetryCreateTradeRequestsMutation,
  type RetryCreateTradeRequestsMutationVariables
} from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { cleanMaybe, Logger } from '@oms/shared/util';
import { toGqlDatasource, type DataSourceCommon, type ICrudService } from '@oms/frontend-foundation';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import type { FetchPolicy } from '@apollo/client';

@singleton()
export class TradeRepairRequestService implements ICrudService<TradeRepairRequestFragment> {
  protected name: string = 'TradeRepairRequestService';
  protected logger: Logger;

  protected fetchPolicy: FetchPolicy = 'cache-first';

  // 🏗️ Constructor ------------------------------------------------------- /

  constructor(
    @inject(ApolloClientRPC) private apolloClient: ApolloClientRPC,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
  }

  // 🔍 Queries --------------------------------------------------------- /

  public watchAll$ = (): Observable<DataSourceCommon<TradeRepairRequestFragment>> => {
    const result = this.apolloClient.watchQuery<
      GetTradeRepairRequestsQuery,
      GetTradeRepairRequestsQueryVariables
    >({
      query: GetTradeRepairRequestsDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 5000
    });

    return result.pipe(
      toGqlDatasource(
        ({ getTradeRepairRequests }) =>
          cleanMaybe(getTradeRepairRequests?.results, []) as TradeRepairRequestFragment[]
      )
    );
  };

  public getTradeRepairRequestById(repairId: string) {
    const query = this.gqlResponse.wrapQuery<
      GetTradeRepairRequestByIdQuery,
      GetTradeRepairRequestByIdQueryVariables
    >({
      query: GetTradeRepairRequestByIdDocument,
      variables: {
        tradeRepairRequestId: repairId
      }
    });

    return query.exec();
  }

  // 💱 Mutations --------------------------------------------------------- /

  public repairCreateTradeRepairRequest(
    tradeRepairRequestId: string,
    repairTrade: RepairCreateTradeRepairRequests['trade']
  ) {
    const mutation = this.gqlResponse.wrapMutate<
      RepairCreateTradeRepairRequests,
      RepairCreateTradeRepairRequestsMutationVariables
    >({
      mutation: RepairCreateTradeRepairRequestsDocument,
      variables: {
        input: {
          data: [
            {
              tradeRepairRequestId,
              trade: repairTrade
            }
          ]
        }
      }
    });

    return mutation.exec();
  }

  public rejectWithReason(ids: string[], rejectText: string) {
    const mutation = this.gqlResponse.wrapMutate<
      RejectTradeRepairRequestsMutation,
      RejectTradeRepairRequestsMutationVariables
    >({
      mutation: RejectTradeRepairRequestsDocument,
      variables: {
        input: {
          data: ids.map((id) => ({
            tradeRepairRequestId: id,
            rejectText: rejectText
          }))
        }
      },
      refetchQueries: [GetTradeRepairRequestsDocument]
    });

    return mutation.exec();
  }

  public retryCreateTradeRequests(ids: string[]) {
    const mutation = this.gqlResponse.wrapMutate<
      RetryCreateTradeRequestsMutation,
      RetryCreateTradeRequestsMutationVariables
    >({
      mutation: RetryCreateTradeRequestsDocument,
      variables: {
        input: {
          tradeRepairRequestIds: ids
        }
      },
      refetchQueries: [GetTradeRepairRequestsDocument]
    });

    return mutation.exec();
  }
}

export default TradeRepairRequestService;
