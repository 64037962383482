import {
  ROUTE_ORDER_INVESTOR_ORDER_IS_PRICE_ENABLED,
  routeOrderInvestorOrderIsPriceEnabled
} from '@app/widgets/trading/route-order/actions/route-order-price-field.action';
import {
  ROUTE_ORDER_INVESTOR_ORDER_IS_SIDE_ENABLED,
  routeOrderInvestorOrderIsSideEnabled
} from '@app/widgets/trading/route-order/actions/route-order-side-field.action';
import {
  ROUTE_ORDER_INVESTOR_ORDER_IS_SYMBOL_ENABLED,
  routeOrderInvestorOrderIsSymbolEnabled
} from '@app/widgets/trading/route-order/actions/route-order-symbol-field.action';
import {
  ROUTE_ORDER_INVESTOR_ORDER_IS_TIF_ENABLED,
  routeOrderInvestorOrderIsTifEnabled
} from '@app/widgets/trading/route-order/actions/route-order-tif-field.action';
import {
  ROUTE_ORDER_VENUE_QUERY_TO_USE,
  routeOrderVenueQueryToUse
} from '@app/widgets/trading/route-order/actions/route-order-venue-field.action';
import {
  ROUTE_ORDER_TRADER_ACTION,
  routeOrderTraderAction
} from '@app/widgets/trading/route-order/actions/route-order-trader-field.action';

import {
  ROUTE_ORDER_INVESTOR_ORDER_IS_LOCATE_ENABLED,
  routeOrderInvestorOrderIsLocateEnabled
} from '@app/widgets/trading/route-order/actions/route-order-locate-field.action';
import {
  ROUTE_ORDER_TRADING_ORDER_IS_SETTLEMENT_ENABLED,
  routeOrderTradingOrderIsSettlementEnabled
} from "@app/widgets/trading/route-order/actions/route-order-settlement.action";

export const ROUTE_ORDER_ACTION_MAPPER = {
  [ROUTE_ORDER_INVESTOR_ORDER_IS_PRICE_ENABLED]: routeOrderInvestorOrderIsPriceEnabled,
  [ROUTE_ORDER_VENUE_QUERY_TO_USE]: routeOrderVenueQueryToUse,
  [ROUTE_ORDER_INVESTOR_ORDER_IS_SIDE_ENABLED]: routeOrderInvestorOrderIsSideEnabled,
  [ROUTE_ORDER_INVESTOR_ORDER_IS_TIF_ENABLED]: routeOrderInvestorOrderIsTifEnabled,
  [ROUTE_ORDER_INVESTOR_ORDER_IS_SYMBOL_ENABLED]: routeOrderInvestorOrderIsSymbolEnabled,
  [ROUTE_ORDER_TRADER_ACTION]: routeOrderTraderAction,
  [ROUTE_ORDER_INVESTOR_ORDER_IS_LOCATE_ENABLED]: routeOrderInvestorOrderIsLocateEnabled,
  [ROUTE_ORDER_TRADING_ORDER_IS_SETTLEMENT_ENABLED]: routeOrderTradingOrderIsSettlementEnabled
};
